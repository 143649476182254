/* Default styles for larger screens */
/* Search container style */

.contain {
  background-image: url("https://www.indigo-herbs.co.uk/sites/default/files/styles/full_content_hero_66pc/public/how%20to%20fruit%20%26%20veg-735w.jpg.webp?itok=o6uFUoK3");
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.contain::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(73, 71, 71, 0.4);
  backdrop-filter: blur(5px);
  z-index: 1;
}

.contain-content {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  padding: 20px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-container {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 10px 20px;
  width: 50%;
}

.search-icon {
  color: #3c3c3c;
  margin-right: 10px;
  cursor: pointer;
}

.search-container input[type="text"] {
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 16px;
}

.close-icon {
  color: #3c3c3c;
  margin-left: 10px;
  cursor: pointer;
}


@media screen and (max-width: 768px) {
  .search-container {
    width: 70%;
    padding: 10px 15px;
  }

  .search-container input[type="text"] {
    font-size: 14px;
  }
}

/* Mobile devices */
@media screen and (max-width: 480px) {
  .search-container {
    width: 90%;
    padding: 8px 10px;
  }

  .search-container input[type="text"] {
    font-size: 12px;
  }

  .search-icon,
  .close-icon {
    font-size: 20px; /* Adjust icon size if needed */
  }
}


/* product content and fruit css */
.container-fluid {
  padding: 2rem;
  
}

.sticky-sidebar {
  position: sticky;
  top: 0;
  padding: 1rem;
  /* background-color: #f1f1f1; */
  border-right: rgba(197, 224, 180, 255) solid 1px;
  height: auto;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
}



.section-header h5 {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 10px;
  
  transition: color 0.3s ease;
  cursor: pointer;
}

.section-header h5:hover {
  color: #ff5722;
}

.sec_img {
  width: 100%!important;
  height: 300px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollable-content {
  max-height: 100vh;
  overflow-y: auto;
  flex:1;
  margin-top: 30px;
  
  /* background-color: antiquewhite; */
}


#varieties {
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color:  rgba(197, 224, 180, 255);
  border-left: 5px solid green;
  color:green;
}


/* .col-3 h3 {
  text-align: center;
  background-color:  rgba(255, 236, 171, 1);
  border-left: 5px solid rgba(197, 224, 180, 255);
  color: green;
} */




.col-9 h1 {
  text-align: center;
}

.section li{
  list-style: none;
}




/* General styles for up to 400px screens */
/* General styles for up to 600px screens */
@media (max-width: 600px) {

  .sticky-sidebar {
    width: 90%!important; 
    margin-bottom: 1rem;
    padding: 0;
    margin-top: 20px!important;
    border:none;
  }
  
  .section {
    display: flex;
    justify-content: center;
   
  }
  .side_bar{
    display: none;
  }
  
  .variety_side_img {
    display: none;
  }
  
  .section ul {
    display: none;
  }
  
  .section {
    width: 100% !important;
  }
  .scrollable-content{
    padding-top: 30px;
  }
 
  
  /* Ensure the right content area occupies full width */
  .col-9 {
    width: 100%!important;
    padding: 0; /* Remove padding for full width */
    margin: 0; /* Remove margin */
    
  }

  .column_product{
    width: 100%;
    box-sizing: content-box!important;
  }
 .row{
   display: flex;
   padding:0px !important;
   justify-content: center;
   align-items: center;
 
   
 }
 .card{
   height: 250px !important;
   
  
 }
 .card-img-top{
   width: 200px;
   height: 180px!important;
 }

 .container-fluid{
  padding: 0px!important;
  margin-bottom: 50px;
  width: 100%!important;

  
 }
 .container{
  padding: 0px!important;
  margin-top: 30px!important;
  width: 100%!important;
  
 }
 .col-9{
  flex:0 0 auto;
  margin: 0px!important;
  padding: 0px!important;
  max-width: 100%!important;
  width: 100% !important;
  
 }

 

 
}

/*  */


















/* ProductPage.css */

.col-9 {
  flex: 0 0 75%; /* Take up 75% of the row width */
  max-width: 75%;
  padding-right: 15px;
  padding-left: 15px;
}


.card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  height: 300px ;
}

.card:hover {
  transform: scale(1.05) rotate(2deg); /* Scale and rotate effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card:hover .card-img-top  {
  filter: brightness(0.8); /* Darken the image slightly on hover */
  
}

.card:hover .card-button {
  transform: translate(-50%, -90%);
  z-index: 10;
  opacity: 1;
 }

 .card-button:hover{
  background-color: #ff5722; /* Change button color */
  color: white; /* Change button text color */
 }

.card-img-top {
  height: 300px; /* Reduced size for images */
  object-fit: cover;
  width:100%;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  transition: filter 0.3s ease-in-out; /* Smooth transition for hover effect */
}

.card-body {
  background-color: #f8f9fa;
  border-radius: 0 0 10px 10px;
  padding-bottom: 50px; /* Add padding to ensure space for the title */
  position: relative; /* Ensure child elements are positioned relative to the card body */
}

.card-title {
  font-size: 20px; /* Font size for the title */
  color: black; /* Change text color for contrast */
  position: absolute; /* Positioning for overlay effect */
  bottom: 0; /* Position at the bottom */
  left: 0; /* Align to the left */
  width: 100%; /* Full width for the text box */
  background: linear-gradient(
    145deg,
    rgba(197, 224, 180, 1) 35%,
    rgba(255, 236, 171, 1) 50%
  ); /* Decorative gradient background */
  padding: 8px; /* Padding for text box */
  text-align: center; /* Center the text */
  box-sizing: border-box; /* Include padding in width calculations */
  margin: 0; /* Remove any default margin */
  z-index: 2; /* Ensure the title is above other elements */
}

.card h5 {
  position: absolute; /* Positioning for the <h5> element */
  bottom: 0; /* Position at the bottom of the card */
  left: 0; /* Align to the left */
  width: 100%; /* Full width for the text box */
  background: linear-gradient(
    145deg,
    rgba(255, 236, 171, 1) 35%,
    rgba(197, 224, 180, 1) 50%
  ); /* Decorative gradient background */
  padding: 8px; /* Padding for text box */
  text-align: center; /* Center the text */
  box-sizing: border-box; /* Include padding in width calculations */
  margin: 0; /* Remove any default margin */
  z-index: 2; /* Ensure the title is above other elements */
}

.card-button {
  transform: translate(-50%, 155%);
  width:auto;
  border-radius: 10px;
  border: none;
  background-color: green;
  color: #fff;
  font-size: 1rem;
  padding: .5rem 1rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  opacity: 0;
  transition: 0.3s ease-out;
 }

 @media screen and (min-width: 770px) and (max-width: 980px)
 {
   .column_product{
     flex-basis: 30%!important;
     width: 100%!important;
   }
   .card{
    height: 270px!important;
    width:200px!important;
   }
 }



/* Base Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: #333;
  margin: 0;
  padding: 0;
}

.product-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.product-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}

.product-images {
  display: flex;
  flex-direction: row;
  width: 60%;
}

.side-images {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  width: 27%;
  
}

.side-image {
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.side-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.side-image:hover img {
  transform: scale(1.05);
}

.side-image.active img {
  border: 3px solid green;
}

.main-image-container {
  width: 90%;
  
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}

.main-image {
  width: 100%;
  height: 640px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.main-image:hover {
  transform: scale(1.05);
}

.product-content1 {
  width: 50%;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.product-title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #2c3e50;
}

.product-subtitle {
  font-size: 18px;
  margin-bottom: 20px;
  color: green;
}

.product-features,
.product-description {
  margin-top: 20px;
}

.product-features h2,
.product-description h2 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #2c3e50;
  border-bottom: 2px solid #2c3e50;
  padding-bottom: 5px;
}

.product-features ul {
  list-style: none;
  padding: 0;
}

.product-features ul li {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 1.5;
}

.product-features li::before {
  content: '✓';
  color: green;
  margin-right: 10px;
}

.flex-boxes {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.toggle-box {
  flex: 1;
  background-color: #ecf0f1;
  border: 1px solid #bdc3c7;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.toggle-box:hover {
  transform: translateY(-3px);
  background-color: green;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.toggle-box.open {
  background-color: green;
  color: #fff;
}

.toggle-box h2 {
  margin: 0;
  font-size: 17px;
  transition: color 0.3s ease;
}

.toggle-box .box-content {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.6;
  transition: opacity 0.3s ease, max-height 0.3s ease;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
}

.toggle-box.open .box-content {
  max-height: 200px;
  opacity: 1;
  visibility: visible;
}

/* Responsive Styles */

/* Responsive Styles */

@media (max-width: 768px) {
  .product-info {
    flex-direction: column;
    align-items: center;
  }

  .product-images {
    width: 100% !important;
    flex-direction: column-reverse;
  }

  .side-images {
    flex-direction: row;
    overflow-x: auto;
    height: 200px !important;
    width: 100%;
    margin-top: 10px;
  }

  .side-image {
    width: 100%;
 
  }
 .side-image img{
  height:150px !important;
 }
  .product-page {
    margin-top: 130px;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .product-info {
    padding: 0;
    width: 100%; /* Ensure the product-info container takes full width */
  }

  .main-image-container {
    width: 100%;
    margin-left: 0; /* Remove any left margin to avoid restricting width */
    padding: 0; /* Ensure no padding is applied */
  }

  .main-image {
    width: 100%; /* Full width */
    height: auto; /* Auto height to maintain aspect ratio */
    margin-right: 0px;
    padding: 0; /* Ensure no padding is applied */
  }

  .product-title {
    font-size: 22px;
  }

  .product-subtitle {
    font-size: 16px;
  }

  .product-features h2,
  .product-description h2 {
    font-size: 20px;
  }
  .product-content1{
    width: 100%;
    margin-top: 0;
  }
}


/* src/styles/ProductPage.css */
/* src/styles/ProductPage.css */
.related-products {
  margin-top: 6rem;
  padding: 2rem;
  /* background: linear-gradient(135deg, #f8f8f8, #e8e8e8); */
  border-radius: 20px;
  /* box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); */
  width: 90%; /* Increased container width */
  margin-left: auto;
  margin-right: auto;
}

.related-products h2 {
  font-size: 40px;

  margin-bottom: 1.5rem;
  color: #333;
  text-align: center; /* Centered heading text */
  font-weight: bolder;
}

.related-products-grid {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  justify-content: center; /* Center items when there's only one or few items */
}

.related-product-item {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: #f8f9fa;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  width: calc(25% - 1.5rem); /* Adjust size for 4 items per row */
  min-width: 250px; /* Ensure a minimum width */
}

.related-product-item:hover {
  transform: scale(1.05) rotate(2deg);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.related-product-item:hover .related-product-image {
  filter: brightness(0.8);
}

.related-product-image {
  height: 270px; /* Slightly increased image size */
  object-fit: cover;
  width: 100%;
  border-radius: 10px 10px 0 0;
  transition: filter 0.3s ease-in-out;
}

.related-product-item .card-title {
  font-size: 1.25rem;
  color: black;
  background: linear-gradient(
    145deg,
    rgba(197, 224, 180, 1) 35%,
    rgba(255, 236, 171, 1) 50%
  );
  padding: 8px;
  text-align: center!important;
  box-sizing: border-box;
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.related-product-item:hover .card-button {
  transform: translate(-50%, -70%); /* Adjusted button position */
  z-index: 10;
  opacity: 1;
}

.card-button {
  transform: translate(-50%, 125%); /* Adjusted button position */
  border-radius: 8px; /* Slightly reduced button border radius */
  border: none;
  background-color: green;
  color: #fff;
  font-size: 0.9rem; /* Slightly decreased font size */
  padding: 0.4rem 0.8rem; /* Slightly reduced padding */
  position: absolute;
  left: 50%;
  bottom: 0;
  opacity: 0;
  transition: background-color 0.3s ease-out, color 0.3s ease-out;
}

.card-button:hover {
  background-color: #ff5722;
  color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .related-product-item {
    width: calc(50% - 1.5rem); /* Two items per row */
  }
}

@media (max-width: 480px) {
  .related-product-item {
    width: calc(100% - 1.5rem); /* One item per row */
  }
}
