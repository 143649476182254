/* Navbar Styling */
#navbar {
  display: flex;
  align-items: center;
  background-color: rgba(197, 224, 180, 255);
  padding: 10px 20px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}

#navbar img {
  width: 100px;
  height: 80px;
  margin-left: 60px;
}

#navbar nav {
  flex-grow: 1;
}

.social-media a {
  margin-left: 10px;
  color: black;
}

.social-media a:hover {
  color: green !important;
}

#navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-right: 40px;
}

#navbar ul li {
  margin: 0 15px;
}

#navbar ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  transition: color 0.3s ease;
  font-size: 22px;
}

#navbar ul li a:hover {
  color: green;
}

#hamburger {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

/* Dropdown Menu Styling */
.dropdown {
  position: relative;
}

.dropdown .product-link {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 0;
  list-style: none;
  z-index: 2;
  width: 380px; /* Updated width */
  text-align: left;
  flex-direction: column;
}

.dropdown-menu li {
  padding: 10px 20px;
  width: 100%; /* Ensure items take full width */
  background-color: rgba(197, 224, 180, 255);
}

.dropdown-menu li a {
  color: black;
  text-decoration: none;
  display: block; /* Ensure the link takes the full area */
  font-weight: 400 !important;
  font-size: 18px !important;
}

.dropdown-menu li:hover {
  background-color: rgba(255, 235, 169, 255);
}

/* Arrow Styling */
.arrow {
  margin-left: 5px;
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.dropdown.open .dropdown-menu {
  display: flex; /* Flex to align items vertically */
  flex-direction: column; /* Vertical dropdown layout */
}

/* Blurred Background for Open Menu */
.blurred-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: blur(40px) !important;
  z-index: -1;
  transition: height 0.3s ease; /* Smooth transition */
}

.blurred-image img {
  max-width: 80%;
  max-height: 80%;
}

/* Media Queries for Small Screens */
@media (max-width: 762px) {
  #navbar img {
    margin-left: 0px;
  }

  #navbar ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    background: transparent;
    width: 100%;
    padding: 10px 20px;
    border-radius: 0;
  }

  #navbar ul.open {
    display: flex;
  }

  #navbar ul li {
    margin: 10px 0;
  }

  #navbar ul li a {
    font-size: 20px;
  }

  #hamburger {
    display: flex;
    gap: 20px;
    color: black;
    cursor: pointer;
  }

  #navbar ul.open .dropdown {
    display: flex;
    flex-direction: column;
    padding-left: 5%;
  }

  #navbar ul.open .dropdown-menu {
    display: inline-block;
    position: static;
    background-color: transparent;
    margin-left: 10%;
  }

  .dropdown-menu li {
    padding: 10px;
    background-color: transparent;
  }

  .blurred-image {
    height: 50%; /* Default height for screens below 762px */
  }

  /* Increase the height when the dropdown menu is open */
  .dropdown.open ~ .blurred-image {
    height: 100%; /* Adjust height to fit the menu */
  }
  #navbar ul li a {
    font-size: 15px;
  }

  .dropdown-menu li a {
    font-size: 15px !important;
  }
  .blurred-image {
    height: 70%; /* Default height for very small screens */
  }
}

@media (max-width: 400px) {
  #navbar ul li a {
    font-size: 15px;
  }

  .dropdown-menu li a {
    font-size: 15px !important;
  }

  .dropdown-menu li {
    padding: 0px;
    background-color: transparent;
  }

  .blurred-image {
    height: 60%; /* Default height for very small screens */
  }

  /* Increase the height when the dropdown menu is open */
  .dropdown.open ~ .blurred-image {
    height: 120%; /* Adjust height for very small screens */
  }
  .dropdown.open .dropdown-menu ~ .blurred-image {
    height: 120%; /* Ensure the blurred image covers the dropdown area */
  }
}

@media (min-width: 762px) {
  .blurred-image {
    display: none;
  }
}

/* Hover and Active State for Links */
#navbar ul li a:hover {
  color: green;
}

#navbar ul li .active {
  color: green;
}
