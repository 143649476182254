.custom-arrows {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.custom-arrow {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

.hidden {
  display: none;
}

#main_section div{
  height: 100vh;
}
.content {
  position: absolute; /* Make it absolute to position it over the image */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position for centering */
  height: auto !important;
  display: flex; /* Use flexbox for alignment */
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center; /* Center text */
  color: black; /* Change text color based on your background */
  padding: 20px; /* Add padding for spacing */
  background-color: rgba(197, 224, 180, 255); 
  backdrop-filter: blur(10px);
  border-radius: 15px; /* Optional: rounded corners */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Optional: subtle shadow */
}

.content h1 {
  font-size: 30px;
  font-style: italic;
  text-align: center;
}

.content h1::first-letter {
  color: green;
  font-weight: bold;
  font-size: 50px;
}

.content h3 {
  font-size: 20px;
  margin-top: 20px;
  text-align: center;
  font-weight: 100;
}

.content button {
  margin-top: 30px;
  /* background-color: rgb(207, 174, 66); */
  /* background-color: rgb(229, 197, 93); */
  border-radius: 5px;
  font-weight: bold;
  color: green;
  cursor: pointer;
}

.content button:hover {
  background-color: rgb(230, 220, 200);
}

/* Media Queries */

@media screen and (max-width: 480px) {
  .content {
    padding: 5px;
    width: 80%;
  }

  .content h1 {
    font-size: 30px;
    color: black;
  }

  .content h1::first-letter {
    font-size: 50px;
    color: #ff5252;
  }

  .content h3 {
    font-size: 16px;
    font-weight: 500;
  }

  .content button {
    padding: 10px;
    font-size: small;
  }
}

@media screen and (max-width: 768px) {
  .content {
    padding: 10px;
    width: 80%;
  }

  .content h1 {
    font-size: 40px;
  }

  .content h1::first-letter {
    font-size: 60px;
  }

  .content h3 {
    font-size: 18px;
  }

  .content button {
    padding: 15px;
    font-size: small;
  }
}
 
@media screen and (min-width: 1024px) {
  .content h1 {
    font-size: 50px;
  }

  .content h1::first-letter {
    font-size: 70px;
  }

  .content h3 {
    font-size: 24px;
  }

  .content button {
    padding: 25px;
    font-size: large;
  }
}
 

.heading-green {
  color: green;
}

#why_choose_us h1 {
  font-size: 40px;
  margin-bottom: 30px;
  margin-top: 90px;
  color: #333;
  text-align: center;
}

.why_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
}

.why_container img {
  width: auto;
  height: 500px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.why_container img:hover {
  transform: scale(1.05);
}

.choose {
  max-width: 500px;
  text-align: left;
  padding: 10px;
}

.choose h2 {
  font-size: 26px;
  margin-bottom: 13px;
  color: #333;
}

.benefits {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.benefits ul {
  list-style: none;
  padding: 0;
}

.benefits li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: clamp(12px, 2.5vw, 18px);
  color: #666;
  margin-top: 9px;
  transition: color 0.3s ease-in-out;
}

.benefits li:hover {
  color: #000;
}

.benefits li span.material-symbols-outlined {
  font-size: clamp(20px, 4vw, 24px);
  color: green;
  transition: color 0.3s ease-in-out;
}

.benefits li:hover span.material-symbols-outlined {
  color: #ff5252;
}

@media (min-width: 300px) and (max-width: 600px) {
  #why_choose_us {
    display: flex;
    flex-direction: column;
  }
  .why_container {
    display: flex;
    flex-direction: row !important;
  }
  .benefits li {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px !important;
    color: #666;
    margin-top: 9px;
    transition: color 0.3s ease-in-out;
  }
  .choose h2 {
    font-size: 24px;
    margin-bottom: 13px;
    color: #333;
  }
  #why_choose_us h1 {
    font-size: 33px;
    margin-bottom: 30px;
    color: #333;
    text-align: center;
    margin-top: 90px;
  }
}

@media (min-width: 601px) and (max-width: 1020px) {
  .why_container {
    display: flex;

    flex-direction: row !important;
    justify-content: center;
    align-items: center;

    padding: 20px;
  }
  .benefits li {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px !important;
    color: #666;
    margin-top: 9px;
    transition: color 0.3s ease-in-out;
  }
  .choose h2 {
    font-size: 24px;
    margin-bottom: 13px;
    color: #333;
  }
  #why_choose_us h1 {
    font-size: 33px;
    margin-bottom: 30px;
    margin-top: 90px;
    color: #333;
    text-align: center;
  }
}

.variety_of_powders {
  margin-top: 50px;
  text-align: center;
}

.variety_of_powders h1 {
  font-size: 40px;
  margin-bottom: 20px;
}

.powders {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.powder_item {
  width: 300px; /* Fixed width for the item */
  text-align: center;
  margin: 10px; /* Space between items */
  background-color: #f9f9f9; /* Light background for contrast */
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Ensure content doesn't overflow */
  position: relative; /* Position for text overlay */
}

.powder_item img {
  width: 100%; /* Make image responsive */
  height: 300px; /* Set fixed height to maintain size */
  object-fit: cover; /* Cover the box without distortion */
}

.powder_item h3 {
  font-size: 20px; /* Font size for the title */
  color: black; /* Change text color for contrast */
  position: absolute; /* Positioning for overlay effect */
  bottom: 0; /* Position at the bottom */
  left: 0; /* Align to the left */
  width: 100%; /* Full width for the text box */
  background: linear-gradient(
    145deg,
    rgba(197, 224, 180, 1) 35%,
    rgba(255, 236, 171, 1) 50%
  ); /* Decorative gradient background */
  padding: 8px; /* Padding for text box */

  text-align: center; /* Center the text */
  box-sizing: border-box; /* Include padding in width calculations */
  margin: 0; /* Remove any default margin */
}

@media screen and (max-width: 900px) {
  .variety_of_powders h1 {
    font-size: 33px;
  }
}

.poster_sec {
  display: flex;
}

.image1_veg {
  width: 50%;
  height: 710px;
}

.rows {
  display: flex;
  flex-wrap: wrap;

  /* padding: 0 4px;
  margin-top: 0px; */
}

.columns {
  flex: 25%;
  /* max-width: 40%;
   
  padding: 0 4px;
  height:600px;
  backdrop-filter: blur(10px); */
  height: 710px;
  overflow-y: hidden !important;
}

.rows h3 {
  font-size: 17px;
  margin-top: 40px;
  font-weight: 100;
  margin-bottom: 30px;
}

.rows h3 button {
  padding: 5px;
  background: green;
  color: white;
  margin-left: 60px;
}

.columns img {
  /* margin-top: 8px; */
  vertical-align: middle;
  width: 100%;
  padding: 5px;
}

/*  */

@media (min-width: 701px) and (max-width: 1024px) {
  .poster_sec {
    flex-direction: row;
    align-items: center;
  }

  .image1_veg {
    width: 100%; /* Make main image full width */
    height: 500px; /* Allow height to adjust automatically */
  }

  .row {
    display: flex; /* Ensure row displays as flex */
    flex-wrap: wrap; /* Allow wrapping of items */
    justify-content: center; /* Center align the row items */
    margin-top: 0px;
  }

  .columns {
    display: flex;
    flex-direction: row; /* Arrange images in a row */
    flex: 50%;
    gap: 5px;
    max-width: 100%;
    height: 300px;
    padding: 10px;
  }

  .col1 {
    display: none !important;
  }

  .columns img {
    width: 50%; /* Make images responsive to column width */
    height: 300px; /* Adjust height automatically */
    max-width: 400px; /* Set a maximum width for smaller images */
    border-radius: 10px;
  }
  .poster_sec h3 {
    margin-left: 30px;
  }
  .poster_sec button {
    /* display: block;
    margin-top: 15px!important;
   margin-left: auto!important; */
    /* margin-top: 15px!important; */
    margin-left: 0 !important;
  }
}

@media (min-width: 1025px) and (max-width: 1250px) {
  .poster_sec {
    flex-direction: row;
    align-items: center;
  }

  .image1_veg {
    width: 100%; /* Make main image full width */
    height: 600px; /* Allow height to adjust automatically */
  }

  .row {
    display: flex; /* Ensure row displays as flex */
    flex-wrap: wrap; /* Allow wrapping of items */
    justify-content: center; /* Center align the row items */
    margin-top: 0px;
  }

  .columns {
    display: flex;
    flex-direction: row; /* Arrange images in a row */
    flex: 60%;
    gap: 5px;
    max-width: 100%;
    height: 400px;
    padding: 10px;
  }

  .col1 {
    display: none !important;
  }

  .columns img {
    width: 49%; /* Make images responsive to column width */
    height: 400px; /* Adjust height automatically */
    max-width: 400px; /* Set a maximum width for smaller images */
    border-radius: 10px;
  }

  .poster_sec button {
    /* display: block;
    margin-top: 15px!important;
   margin-left: auto!important; */
    margin-top: 15px !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 700px) {
  .poster_sec {
    flex-direction: column;
    align-items: center;
  }

  .image1_veg {
    width: 100%;
    height: 300px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .columns {
    display: flex;
    flex-direction: row;
    flex: 30%;
    max-width: 100%;
    height: 250px;
  }

  .col1 {
    display: none !important;
  }

  .columns img {
    width: 50%;
    height: 250px;
    max-width: 400px;
  }
  .poster_sec button {
    /* display: block;
    margin-top: 15px!important;
   margin-left: auto!important; */
    margin-top: 15px !important;
    margin-left: 0 !important;
  }
}
.products-section {
  text-align: center;
  padding: 40px 0;
}

.products-section h1 {
  font-size: 39px;
  margin-bottom: 40px;
  color: #333; /* Dark color for the title */
}

.carousel-items {
  padding: 5px; /* Space between carousel items */
}

#quality {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease; /* Smooth transform on hover */
  position: relative;
  background: linear-gradient(
    to bottom,
    rgba(197, 224, 180, 255),
    rgba(255, 236, 171, 255)
  );
  max-width: 380px;
}

#quality p {
  margin-top: 10px;
  padding: 10px;
  color: green;
  text-align: left;
  min-height: 200px;
  max-height: 250px;
}

#quality:hover {
  transform: translateY(-10px); /* Slight lift on hover */
}

#quality img {
  width: 100%;
  height: 250px;
  object-fit: auto;
}

#quality h3 {
  background: rgba(
    197,
    224,
    180,
    255
  ); /* Semi-transparent dark background for text */
  color: green; /* White text color for better contrast */
  font-size: 24px; /* Font size for the title */
  text-align: left; /* Center the text */
  margin-top: -4px;
  padding: 3px 0px 10px 10px;
}

.custom-arrows2 {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.custom-arrow2 {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white !important;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

@media screen and (max-width: 900px) {
  .products-section h1 {
    font-size: 33px;
  }
}

.enquiry-section {
  text-align: center;
  padding: 40px 20px;
  background-color: rgba(255, 236, 171, 255);
  height: 500px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 60px;
  gap: 0px; /* Add gap between the two sections */
}

.contact-info {
  width: 500px;
  text-align: left;
  font-size: 18px;
  color: #333;
  background-color: rgba(197, 224, 180, 1);

  padding: 30px;
  border-radius: 10px;
}

.contact-info h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.contact-info p {
  margin-bottom: 20px;
  line-height: 1.6;
}

.form-container {
  flex: 0.5;
  text-align: left;
}

.form-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.enquiry-form {
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;

  background-color: transparent;
  border: 1px solid black;
  box-sizing: border-box;
}

.form-group textarea {
  height: 100px;
  resize: none;
}

button {
  background-color: green;
  color: white;
  padding: 15px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  align-self: center;
}

button:hover {
  background-color: #45a049;
}

@media (max-width: 580px) {
  .enquiry-section {
    display: flex;
    flex-direction: column !important;
    height: auto;
    font-size: small;
  }
  .contact-info,
  .form-container {
    width: 100% !important;
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .enquiry-section {
    flex-direction: row;
    height: auto;
    gap: 20px;
  }

  .contact-info,
  .form-container {
    width: 50%;
    margin-bottom: 20px;
  }

  .form-container {
    flex: 1.5;
    margin-right: 40px;
  }
}

.footer {
  background-color: rgba(197, 224, 180, 255);
  color: black;
  padding: 20px 0;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}

.footer-logo,
.footer-links,
.footer-contact,
.footer-products {
  flex: 1;
  min-width: 250px;
  margin: 10px;
}

.footer h2,
.footer h3 {
  margin-bottom: 15px;
}

.footer p,
.footer a {
  margin-bottom: 10px;
}

.footer-links ul,
.footer-products ul {
  list-style: none;
  padding: 0;
}

.footer-links li,
.footer-products li {
  margin-bottom: 10px;
}

.footer a:hover {
  text-decoration: none;
  color: blue;
}

.footer-logo img {
  max-width: 100px;
  margin-bottom: 10px;
}

.footer-bottom {
  text-align: center;
  padding: 10px 0;
  background-color: #ddd;
  margin-top: 20px;
  font-size: 0.9em;
}

.social-media1 a {
  margin: 0 10px;
  transition: color 0.3s;
}

.social-media1 a:hover {
  color: green;
}

.footer-products ul li::before {
  content: "➤";
  margin-right: 10px;
  color: green;
  font-size: 1.2em;
}

.footer-links ul li::before {
  content: "➤";
  margin-right: 10px;
  color: green;
  font-size: 1.2em;
}

@media (max-width: 600px) {
  .social-media1 {
    display: contents !important;
  }
}

@media (max-width: 600px) {
  .powder_item {
    width: 100%; /* Fixed width for the item */
    text-align: center;
    border-radius: 0px;
    margin: 5px;
    margin-bottom: 50px; /* Space between items */
    overflow: hidden; /* Ensure content doesn't overflow */
    position: relative; /* Position for text overlay */
  }

  .powder_item img {
    width: 100%;
    height: 450px;
    object-fit: cover;
  }
}

#landing_page {
  width: 100%;
  height: 100vh;
}
#home_section {
  background-image: url("./assets/images/landing.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 28vh;
}

.landing-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.landing {
  width: 50%;
  height: 100vh;
}

.manufacturing_unit {
  height: 100vh;
}

#home_button {
  display: flex;
  align-items: center;
  background-color: green;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
}

#home_button:hover {
  background-color: darkgreen;
  transform: scale(1.05);
}

.home_button_text {
  margin-right: 8px;
}

.home_button_icon {
  font-size: 1.2em;
  padding-top: 3px;
}

@media screen and (max-width: 440px) {
  #home_section {
    background-position: center center;
    background-size: contain;
    height: 100vh;
  }
  #home_button {
    display: flex;
    align-items: center;
    background-color: green;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    position: absolute;
    top: 33vh;
  }
  .landing-container {
    flex-direction: column;
  }

  .manufacturing_unit {
    display: none;
  }

  .landing {
    width: 100% !important;
    height: auto;
  }
}

@media screen and (min-width: 440px) and (max-width: 500px) {
  #home_section {
    background-size: cover;
  }
  .landing-container {
    flex-direction: column;
  }
  .landing,
  .manufacturing_unit {
    width: 100%;
    height: 50vh;
  }
}

@media screen and (min-width: 500px) and (max-width: 1024px) {
  #home_section {
    background-size: contain;
  }
  .landing-container {
    flex-direction: column;
  }
  .landing,
  .manufacturing_unit {
    width: 100%;
    height: 50vh;
  }
}





